<template>
  <div class="register-model" v-if="show">
    <van-popup
      :close-on-click-overlay="false"
      :round="true"
      :overlay="true"
      :overlay-style="{ background: 'rgba(0,0,0,0.3)' }"
      v-model.sync="show"
    >
      <div class="model-view" v-if="langInfo">
        <!-- 是否购买过产品 -->
        <div
          class="model-title"
          v-html="langInfo.newLangInfo.registerModel.title"
        ></div>
        <!-- 购买过  -->
        <div
          class="model-button blue"
          v-html="langInfo.newLangInfo.registerModel.button1"
          @click="handClick('y')"
        ></div>
        <!-- 去购买 -->
        <div
          class="model-button brown"
          v-html="langInfo.newLangInfo.registerModel.button2"
          @click="handClick('s')"
          
        ></div>
        <div
          class="model-checkbox"
          :class="[isAgree ? 'isselect' : '', isShake ? 'apply-shake' : '']"
        >
          <img
            class="checkbox"
            @click="isAgree = true"
            v-if="!isAgree"
            src="../../assets/images/checkbox.png"
          />
          <img
            class="checkbox"
            @click="isAgree = false"
            v-else
            src="../../assets/images/checkbox-true.png"
          />
          <div class="text" v-if="Nation === 'TH'">
            <span>เข้าสู่ระบบหรือลงทะเบียนเพ </span>
            <span
              style="text-decoration: underline"
              @click="$router.push('/terms')"
              >ื่อยอมรับข้อตกลงการใช้บริการผู้ใช้</span
            >
            <span
              style="text-decoration: underline"
              @click="$router.push('/policy')"
              >ื่Simplus และนโยบายความเป็นส่วนตัว</span
            >
          </div>
          <div class="text" v-else-if="Nation === 'VN'">
            <span>Đăng nhập hoặc đăng ký có nghĩa </span>
            <span
              style="text-decoration: underline"
              @click="$router.push('/terms')"
              >là bạn chấp nhận Điều khoản sử dụng và
            </span>
            <span
              style="text-decoration: underline"
              @click="$router.push('/policy')"
              >Chính sách bảo mật của Simplus.</span
            >
          </div>
          <div class="text" v-else-if="Nation === 'ID'">
            <span>Masuk atau daftar berarti </span>
            <span
              style="text-decoration: underline"
              @click="$router.push('/terms')"
              >Anda menerima Ketentuan Penggunaan
            </span>
            <span
              style="text-decoration: underline"
              @click="$router.push('/policy')"
              >dan Kebijakan Privasi Simplus.</span
            >
          </div>
          <div class="text" v-else>
            <span>Registration means that you accept Simplus</span>
            <span>
              <span
                style="text-decoration: underline"
                @click="$router.push('/terms')"
                >Terms of Use</span
              >
              and
              <span
                style="text-decoration: underline"
                @click="$router.push('/policy')"
                >Privacy Policy.</span
              >
            </span>
          </div>
        </div>

        <!-- 已经是会员 直接跳转登录 -->
        <!-- <div class="model-button blue" v-html="langInfo.newLangInfo.registerModel.button3"
					@click="handClick('s')"></div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { List } from "../../util/en_config_new";
import { langData } from "../../util/lang_config_new";
import { Popup, Toast, Dialog } from "vant";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    Nation: {
      type: String,
      default: "",
    },
  },
  watch: {
    Nation(val) {
      if (!val) {
        return;
      }
      this.langInfo = this.setLang();
    },
  },
  components: {
    [Popup.name]: Popup,
  },
  data() {
    return {
      showDataInfo: null,
      langInfo: null,
      isAgree: false,
      isShake: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.register) {
        this.$router.push({
          query: {},
        });
      }
    },
    handClick(t) {
      if (!this.isAgree && t == "y") {
        if (this.isShake) {
          return;
        }
        this.isShake = true;
        setTimeout(() => {
          this.isShake = false;
        }, 1000);
        return;
      }
      switch (t) {
        case "y":
          this.$router.push("/register");
          break;
        case "n":
          this.$router.push("/guide");
          break;
        case "s":
          this.$emit("next", true);
          break;
      }
    },
    setLang() {
      let Info = langData.filter((res) => res.Nation == this.Nation)[0];
      this.showDataInfo = List.filter((res) => res.Nation == this.Nation)[0];
      return Info == undefined ? langData[0] : Info;
    },
  },
};
</script>

<style lang="less" scoped>
.register-model {
  .model-view {
    flex: 1;
    padding: 18px;
    width: 65vw;
    background: #ffffff;
    border-radius: 24px;

    .model-title {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #292929;
      text-align: center;
      line-height: 18px;
      margin: 24px 0px 28px;
    }

    .model-button {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #292929;
      text-align: center;
      border-radius: 24px;
      margin-bottom: 24px;
    }

    .blue {
      background: #245bd9;
      color: #ffffff;
      border: 1px solid #245bd9;
    }

    .brown {
      background: #ffffff;
      color: #000000;
      border: 1px solid #333333;
    }

    .model-checkbox {
      display: flex;
      align-items: center;
      padding: 18px 0px;

      .checkbox {
        width: 18px;
        height: 18px;
      }

      .text {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4c5e9c;
        transform: scale(0.8) translateX(-20px);
        white-space: nowrap;
      }
    }

    .isselect {
      .text {
      }
    }
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
