<template>
	<div class="page-index">
		<div class="app-logo">
			<img class="logo" src="../../assets/images/logo.png">
		</div>
		<select-country v-if="!isLogin" :labelIndex="zIndex" :isShow="!isLogin" :text="Nation"
			@change="(e)=>{Nation = e;isLogin = false;zIndex = '2016'}" />
		<register-model v-if="!isLogin" :show="Nation&&!isLogin?true:false" :Nation="Nation" @next="(e)=>isLogin = e" />
		<login-model v-if="isLogin" :show="isLogin" @signup="handReload" />
	</div>
</template>

<script>
	import selectCountry from '../../components/select-country/select-country.vue';
	import registerModel from '../../components/register-model/register-model.vue';
	import loginModel from '../../components/login-model/login-model.vue';
	export default {
		components: {
			selectCountry,
			registerModel,
			loginModel
		},
		data() {
			return {
				Nation: '',
				isLogin: false,
				zIndex: '1999'
			};
		},
		created() {
			localStorage.removeItem('RegisterInfo');
			if (this.$route.query.login) {
				setTimeout(() => {
					this.isLogin = true;
					this.$router.push({
						query: {}
					})
				}, 100)
			}
			if (this.$route.query.register || localStorage.getItem('NationRegShow')) {
				localStorage.removeItem('NationRegShow')
				setTimeout(() => {
					this.zIndex = '1990'
					if (localStorage.getItem('NationName')) {
						this.Nation = localStorage.getItem('NationName');
					}
				}, 100)
			}
			if (localStorage.getItem('registerShow')) {
				localStorage.removeItem('registerShow')
				setTimeout(() => {
					this.zIndex = '2016'
					if (localStorage.getItem('NationName')) {
						this.Nation = localStorage.getItem('NationName');
					}
				}, 100)
			}
		},
		methods: {
			handReload() {
				location.reload();
				localStorage.setItem('registerShow', true)
			}
		}
	};
</script>

<style lang="less">
	.page-index {
		width: 100vw;
		height: 100vh;
		background: url('../../assets/images/bg-image.png') no-repeat;
		background-size: 100% 100%;
		background-size: contain;
		background-position: bottom;
		-webkit-background-size: contain;
		-o-background-size: contain;
		background-color: #e4e8f1;

		.app-logo {
			padding: 88px;
			display: flex;
			justify-content: center;

			.logo {
				width: 220px;
				height: 60px;
			}
		}
	}
</style>